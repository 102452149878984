import { useState, ChangeEvent, useEffect, CSSProperties } from 'react';
import { Box, FormControl, FormLabel, Input, InputGroup, List, ListItem } from '@chakra-ui/react';
import { fuzzySearchArray } from '../../utils/stringUtils.js';

interface ListItems {
  id?: string;
  name?: string;
}

const SearchableInput = <T extends ListItems>({
  list,
  id,
  label,
  labelStyles,
  required,
  value,
  selectOptionAction,
  onChangeAction,
  readonly,
}: {
  list: T[];
  id: string;
  label: string;
  value?: string;
  labelStyles?: CSSProperties;
  required?: boolean;
  readonly?: boolean;
  selectOptionAction?: (value: string) => void;
  onChangeAction?: () => void;
}) => {
  const [query, setQuery] = useState<string>('');
  const [filteredData, setFilteredData] = useState<string[]>([]);
  const [searchableData, setSearchableData] = useState<string[]>([]);
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [valueIsSet, setValueIsSet] = useState<boolean>(false);

  useEffect(() => {
    setQuery(value);
  }, [value]);
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValueIsSet(false);
    setShowOptions(true);
    if (onChangeAction) {
      onChangeAction();
    }
    const value = event.target.value;
    setQuery(value);
    if (value && value.length) {
      setFilteredData(fuzzySearchArray(query, searchableData));
    } else {
      setFilteredData(searchableData);
    }
  };

  const onInputFocus = () => {
    setShowOptions(true);
    if (query && query.length) {
      setFilteredData(fuzzySearchArray(query, searchableData));
    } else {
      setFilteredData(searchableData);
    }
  };

  const handleOptionClick = (e: any, selected: string) => {
    e.preventDefault();
    setQuery(selected);
    setValueIsSet(true);
    setShowOptions(false);
    if (selectOptionAction) {
      selectOptionAction(selected);
    }
  };

  useEffect(() => {
    const data = list.map((item) => item.name);
    setSearchableData(data);
    setFilteredData(data);
  }, [list]);

  useEffect(() => {
    filteredData.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
  }, [filteredData]);

  return (
    <Box width="100%" margin="0 auto" mt={4} position="relative">
      <FormControl>
        <FormLabel htmlFor={id} sx={labelStyles}>
          {label}
          {required && <span style={{ color: '#E76262' }}>{` *`}</span>}
        </FormLabel>
        <Input
          onChange={handleInputChange}
          id={id}
          readOnly={readonly}
          onFocus={onInputFocus}
          value={query}
          onBlur={() => setTimeout(() => setShowOptions(false), 150)}
          autoComplete="off"
        />
      </FormControl>
      {showOptions && (
        <List
          mt={2}
          spacing={2}
          border="1px solid var(--border-color-lighter)"
          borderRadius="md"
          maxHeight="300px"
          overflowY="auto"
          position="absolute"
          width="100%"
          bg="#232323"
          zIndex={1}
        >
          {filteredData.map((name, index) => (
            <ListItem
              key={index}
              padding={2}
              _hover={{ backgroundColor: 'var(--border-color-lighter)', cursor: 'pointer' }}
              onMouseDown={(e) => handleOptionClick(e, name)}
            >
              {name}
            </ListItem>
          ))}
        </List>
      )}
    </Box>
  );
};

// const AppInput = ({ label, id, ...inputProps }: ReusableInputProps) => {
//     return (
//       <FormControl w="100%">
//         <FormLabel htmlFor={id}>{label}</FormLabel>
//         <Input outlineColor="var(--color--blue" id={id} {...inputProps} />
//       </FormControl>
//     );
//   };

export default SearchableInput;
