import { useQuery } from 'react-query';
import { useContext, useEffect, useState } from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Grid,
  IconButton,
  Tag,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  Button,
  Spinner,
  Text,
  useToast,
} from '@chakra-ui/react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';

import TopNavBar from '../shared/component/TopNavBar.js';
import { useTitle } from '../shared/hook/useTitle.js';
import { LmmImage, LmmJob, LmmJobResponse } from '../shared/entity.js';
import { GlobalContext } from '../context/GlobalContext.js';
import DISPATCH_ACTIONS from '../context/actions.js';
import { ComponentStyle } from '../interface.js';
import { useScreenDimensions } from '../shared/hook/useScreenDimensions.js';
import { JobCategory, JobStatus } from '../shared/const.js';
import { capitalizeEachWord, formatAsCurrencyAlt } from '../utils/stringUtils.js';
import { sumNumberArray } from '../utils/numberUtils.js';
import { MyIcon } from '../shared/component/Icons.js';
import TrashIcon from '../asset/icons/TrashIcon.js';
import { TopPageActions } from './TopPageActions.js';
import * as s from './Plan.module.css';
import { PlanTable } from './PlanTable.js';

export function Plan() {
  const { state, dispatch } = useContext(GlobalContext);
  const { height } = useScreenDimensions();
  const [estimateCost, setEstimateCost] = useState<{ maintenance: number; backlog: number }>({
    maintenance: 0,
    backlog: 0,
  });
  const [jobInEdit, setJobInEdit] = useState<LmmJobResponse>(null);
  const [jobToDelete, setJobToDelete] = useState<LmmJobResponse>(null);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const toast = useToast();
  useTitle('Plan');

  const { refetch: refetchImages } = useQuery<LmmImage[]>('/api/images?pci%5B0%5D=0&pci%5B1%5D=100', {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: true,
    async onSuccess(newLmmImages) {
      dispatch({ type: DISPATCH_ACTIONS.SET_LMMIMAGES, payload: newLmmImages });
    },
  });

  useEffect(() => {
    const estimatedCostCopy = { ...estimateCost };
    if (state.backLogJobs.length) {
      const costs = state.backLogJobs.map((job) => job.estimatedCost);
      estimatedCostCopy.backlog = sumNumberArray(costs);
    }
    if (state.maintenanceJobs.length) {
      const costs = state.maintenanceJobs.map((job) => job.estimatedCost);
      estimatedCostCopy.maintenance = sumNumberArray(costs);
    }
    setEstimateCost(estimatedCostCopy);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.backLogJobs, state.maintenanceJobs]);

  const { refetch: refetchJobs } = useQuery<LmmJob[]>('/api/jobs', {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: true,
    async onSuccess(jobs) {
      const backLogJobs = jobs.filter((j) => j.category == JobCategory.backlog);
      const maintenanceJobs = jobs.filter((j) => j.category == JobCategory.maintenance);
      dispatch({ type: DISPATCH_ACTIONS.SET_LMMJOBS, payload: { category: JobCategory.backlog, jobs: backLogJobs } });
      dispatch({
        type: DISPATCH_ACTIONS.SET_LMMJOBS,
        payload: { category: JobCategory.maintenance, jobs: maintenanceJobs },
      });
    },
  });

  const handleRefetchJobs = () => {
    refetchJobs();
  };

  const setMaintenanceJobs = (jobs: LmmJobResponse[], movedRow: LmmJobResponse) => {
    dispatch({ type: DISPATCH_ACTIONS.SET_LMMJOBS, payload: { category: JobCategory.maintenance, jobs } });
    const isExist = jobs.find((x) => x.id == movedRow.id);
    if (isExist) {
      updateJobStatus(movedRow, JobCategory.maintenance);
    }
  };

  const setBacklogJobs = (jobs: LmmJobResponse[], movedRow: LmmJobResponse) => {
    dispatch({ type: DISPATCH_ACTIONS.SET_LMMJOBS, payload: { category: JobCategory.backlog, jobs } });
    const isExist = jobs.find((x) => x.id == movedRow.id);
    if (isExist) {
      updateJobStatus(movedRow, JobCategory.backlog);
    }
  };

  //Drag and dop logic
  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;

    // If there's no destination (e.g., dropped outside the list), do nothing
    if (!destination) return;

    // If dropped in the same list in the same position, do nothing
    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return;
    }

    // Get the source list (which table it was dragged from)
    const sourceList = source.droppableId === 'maintenanceTable' ? state.maintenanceJobs : state.backLogJobs;
    const setSourceList = source.droppableId === 'maintenanceTable' ? setMaintenanceJobs : setBacklogJobs;

    // Get the destination list (which table it was dragged to)
    const destinationList = destination.droppableId === 'maintenanceTable' ? state.maintenanceJobs : state.backLogJobs;
    const setDestinationList = destination.droppableId === 'maintenanceTable' ? setMaintenanceJobs : setBacklogJobs;

    // Remove item from source list
    const [movedRow] = sourceList.splice(source.index, 1);

    // Add the item to the destination list
    destinationList.splice(destination.index, 0, movedRow);

    // Update both lists
    setSourceList([...sourceList], movedRow);
    setDestinationList([...destinationList], movedRow);
  };

  const updateJobStatus = (job: LmmJobResponse, category: JobCategory) => {
    fetch(`/api/jobs/${job.id}`, {
      body: JSON.stringify({ category }),
      method: 'put',
      headers: { 'content-type': 'application/json' },
    })
      .then((data) => {
        refetchJobs();
      })
      .catch((err) => {});
  };

  const onClose = () => {
    setJobInEdit(null);
  };

  const handleRowClicked = (job: LmmJobResponse) => {
    setJobInEdit(job);
  };

  const onDeleteConfirm = (job: LmmJobResponse) => {
    setJobToDelete(job);
  };

  const handleDeleteJob = () => {
    setIsDeleting(true);
    fetch(`/api/jobs/${jobToDelete.id}`, {
      method: 'delete',
      headers: { 'content-type': 'application/json' },
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.error) {
          setIsDeleting(false);
          toast({
            title: 'Error!! Unable to delete job',
            status: 'error',
          });
          setJobToDelete(null);
        } else {
          setIsDeleting(false);
          toast({
            title: 'Job deleted succesfully',
            status: 'success',
          });
          handleRefetchJobs();
          setJobToDelete(null);
        }
      })
      .catch((err) => {
        setIsDeleting(false);
        console.error(err);
        toast({
          title: 'Oops! Something went wrong.',
          description:
            'An unexpected error occurred. Please try again later. If the problem persists, contact support.',
          status: 'error',
        });
      });
  };

  return (
    <Grid>
      <Modal
        blockScrollOnMount={false}
        isCentered
        isOpen={!!jobToDelete}
        onClose={() => setJobToDelete(null)}
        returnFocusOnClose={false}
        closeOnEsc={false}
      >
        <ModalOverlay sx={{ background: 'rgba(0, 0, 0, 0.50);' }} />
        <ModalContent
          bg="var(--bg-color)"
          sx={{ width: '45%', minWidth: '400px', maxWidth: '450px', top: '20px', borderRadius: '24px' }}
        >
          <ModalBody px="32px" pt="32px">
            <ModalHeader mx="0px" px="0px" pt="0px">
              <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                <p style={{ fontSize: '24px', fontWeight: '700' }}>Delete Job?</p>
                <IconButton
                  title="Open filter options"
                  aria-label="Open filter options"
                  icon={<MyIcon icon="close" style={{ color: '#979BA6' }} />}
                  size="md"
                  onClick={() => setJobToDelete(null)}
                  sx={{ background: 'none', '&:hover': { background: 'none' } }}
                />
              </Box>
              <Box backgroundColor="var(--separator-color)" height="1px" width="100%" mt="16px" mb="8px"></Box>
            </ModalHeader>
            <Text fontSize="16px" fontWeight="600">
              {`Are you sure you want to delete this job for “${capitalizeEachWord(jobToDelete?.roadName)}”? You can’t undo this.`}
            </Text>
            <Box display="flex" flexDirection="row" my="23px">
              <Button marginRight="12px" onClick={() => setJobToDelete(null)} width="50%">
                Cancel
              </Button>
              <Button background="#E76262" color="#fff" onClick={handleDeleteJob} isDisabled={isDeleting} width="50%">
                {!isDeleting && 'Delete'}
                {isDeleting && (
                  <Spinner
                    width="25px"
                    height="25px"
                    thickness="3px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color="gray.500"
                    size="xl"
                  />
                )}
              </Button>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Box sx={{ mx: '24px' }}>
        <TopNavBar screen="Plan" />
        <TopPageActions refetchJobs={handleRefetchJobs} onClose={onClose} job={jobInEdit} />
      </Box>

      <DragDropContext onDragEnd={onDragEnd}>
        <Box marginTop="18px" overflowY="auto" height={`${height - 190}px`} sx={{ px: '24px' }}>
          <Accordion defaultIndex={[0, 1]} allowMultiple p="0px">
            <AccordionItem p="16px" mb="16px" backgroundColor="#383839" border="1px solid #464647" borderRadius="12px">
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  p: '0 0 16px 0',
                  borderBottom: '2px solid #464647',
                }}
              >
                <AccordionButton width="40%" p="0px" m="0px">
                  <AccordionIcon color="var(--border-color-lighter)" marginRight="16px" ml="10px" />
                  <p style={{ fontSize: '18px', fontWeight: '600' }}>Maintenance Plan</p>
                  <Box
                    sx={{
                      color: '#3888FF',
                      backgroundColor: 'rgba(56, 136, 255, 0.15)',
                      fontSize: '14px',
                      fontWeight: 500,
                      padding: '4px 12px',
                      borderRadius: '50px',
                      marginLeft: '16px',
                    }}
                  >
                    {`${state.maintenanceJobs.length} Job${state.maintenanceJobs.length > 1 ? 's' : ''}`}
                  </Box>
                </AccordionButton>
                <Box>
                  {/* <p style={{ fontSize: '16px', fontWeight: '500', margin: 0, padding: 0 }}>
                  <span style={{ color: 'var(--border-color-lighter)', fontWeight: '400', marginRight: '5px' }}>
                    Budget:
                  </span>
                  $236.2K / $300K
                </p> */}
                  <p style={{ fontSize: '16px', fontWeight: '500', margin: 0, padding: 0 }}>
                    <span style={{ color: 'var(--border-color-lighter)', fontWeight: '400', marginRight: '5px' }}>
                      Estimated Cost:
                    </span>
                    {formatAsCurrencyAlt(estimateCost.maintenance, true)}
                  </p>
                </Box>
              </Box>

              <AccordionPanel p={0}>
                <PlanTable
                  rows={state.maintenanceJobs}
                  tableId="maintenanceTable"
                  setJobInEdit={setJobInEdit}
                  onDeleteJob={onDeleteConfirm}
                />
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem p="16px" backgroundColor="#383839" border="1px solid #464647" borderRadius="12px">
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  p: '0 0 16px 0',
                  borderBottom: '2px solid #464647',
                }}
              >
                <AccordionButton width="40%" p="0px" m="0px">
                  <AccordionIcon color="var(--border-color-lighter)" marginRight="16px" ml="10px" />
                  <p style={{ fontSize: '18px', fontWeight: '600' }}>Backlog</p>
                  <Box
                    sx={{
                      color: '#3888FF',
                      backgroundColor: 'rgba(56, 136, 255, 0.15)',
                      fontSize: '14px',
                      fontWeight: 500,
                      padding: '4px 12px',
                      borderRadius: '50px',
                      marginLeft: '16px',
                    }}
                  >
                    {`${state.backLogJobs.length} Job${state.maintenanceJobs.length > 1 ? 's' : ''}`}
                  </Box>
                </AccordionButton>
                <Box>
                  <p style={{ fontSize: '16px', fontWeight: '500', margin: 0, padding: 0 }}>
                    <span style={{ color: 'var(--border-color-lighter)', fontWeight: '400', marginRight: '5px' }}>
                      Estimated Cost:
                    </span>
                    {formatAsCurrencyAlt(estimateCost.backlog, true)}
                  </p>
                </Box>
              </Box>

              <AccordionPanel p={0}>
                <PlanTable
                  rows={state.backLogJobs}
                  tableId="backlogTable"
                  setJobInEdit={setJobInEdit}
                  onDeleteJob={onDeleteConfirm}
                />
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Box>
      </DragDropContext>
    </Grid>
  );
}

const styles = {
  tableHeader: {
    fontSize: '14px',
    lineHeight: '24px',
    color: 'var(--border-color-lighter)',
    fontWeight: '600',
    border: 'none',
  },
  tableData: {
    color: '#ffffff',
    fontSize: '16px',
    lineHeight: '24px',
    border: 'none',
  },
  checkBox: {
    border: 'none',
  },
  tableRow: {
    backgroundColor: '#464647',
    height: '56px',
    '&:hover': {
      backgroundColor: '#5a5a5b',
      cursor: 'pointer',
    },
  },
  tableBody: {
    backgroundColor: '#464647',
    borderRadius: '8px',
  },
};
