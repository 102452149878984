import { Tag } from '@chakra-ui/react';
import { JobStatus } from '../shared/const.js';

export function StatusTag({ status }: { status: JobStatus }) {
  switch (status) {
    case JobStatus.in_progress:
      return (
        <Tag color="#ECA336" backgroundColor="rgba(236, 163, 54, 0.15);" borderRadius="24px" padding="4px 12px">
          In Progress
        </Tag>
      );
    case JobStatus.delayed:
      return (
        <Tag color="#E76262" backgroundColor="rgba(231, 98, 98, 0.15)" borderRadius="24px" padding="4px 12px">
          Delayed
        </Tag>
      );
    case JobStatus.completed:
      return (
        <Tag color="#3ECE80" backgroundColor="rgba(62, 206, 128, 0.15)" borderRadius="24px" padding="4px 12px">
          Completed
        </Tag>
      );

    default:
      return (
        <Tag
          color="var(--border-color-lighter)"
          backgroundColor="rgba(151, 155, 166, 0.15)"
          borderRadius="24px"
          padding="4px 12px"
        >
          Not Started
        </Tag>
      );
  }
}
